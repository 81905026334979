.datatable {
  height: 600px;
  padding: 20px;
  
  .datatableTitle {
    width: 100%;
    font-size: 24px;
    color: gray;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .link {
      text-decoration: none;
      color: green;
      font-size: 16px;
      font-weight: 400;
      border: 1px solid green;
      padding: 5px;
      border-radius: 5px;
      cursor: pointer;
    }
  }

  .cellWithImg {
    display: flex;
    align-items: center;

    .cellImg {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      object-fit: cover;
      margin-right: 20px;
    }
  }

  .cellWithStatus {
    padding: 5px;
    border-radius: 5px;

    &.active {
      background-color: rgba(0, 128, 0, 0.05);
      color: green;
    }
    &.pending {
      background-color: rgba(255, 217, 0, 0.05);
      color: goldenrod;
    }
    &.passive {
      background-color: rgba(255, 0, 0, 0.05);
      color: crimson;
    }
  }

  .cellAction {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .viewButton, .deleteButton, .confirmButton, .cancelButton {
    padding: 5px 10px;
    border: 1px dashed;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
  }

  .viewButton {
    color: blue;
    border-color: blue;
  }

  .deleteButton {
    color: red;
    border-color: red;
  }

  .confirmButton {
    color: green;
    border-color: green;
  }

  .cancelButton {
    color: orange;
    border-color: orange;
  }

  .viewButton:hover, .deleteButton:hover, .confirmButton:hover, .cancelButton:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
}